const faqs = [
  {
    question: "Documentos necesarios para viajar a Europa",
    answer: (
      <div>
        <p>Para entrar a la Unión Europea, necesitamos:</p> <br/>
        <p>- Pasaporte vigente (Su pasaporte debe tener una validez mínima de seis meses después de la fecha prevista de entrada en Europa). </p>
        <p>- Pasaje de ida y vuelta (Para ingresar al espacio Schengen es necesario tener un pasaje de salida).</p>
        <p>- Moderna.</p>
        <p>- Reserva o carta de invitación (Las reservas tienen que ser consistentes con el plan de viaje que presentarás y la carta de invitación debe ser expedida por un organismo oficial). </p>
        <p>- Dinero suficiente con una solvencia por día (El espacio Schengen requiere una solvencia de 65 euros por cada día de viaje).</p>
        <p>- Asistencia al viajero.</p>
        <p>- Certificado de Vacunación (Antes de viajar a la mayoría de los países europeos deberás demostrar que te has vacunado contra las vacunas requeridas por ese territorio). </p>
        <p>- No necesitarás un permiso o visa antes de noviembre de 2023 (Haber pasado esa fecha tendrás que presentar la VISA ETIAS). </p>
      </div>
    ),
  },
  {
    question: "Nueva VISA ETIAS para ingresar argentinos y otros países a la UE",
    answer: (
      <div>
        <p>La Unión Europea a partir de noviembre de 2023 pondrá en marcha el nuevo sistema ETIAS, un permiso de viaje para ciudadanos de terceros países que se encuentran exentos de visa para viajar a los más de 20 países del espacio Schengen, todo esto con la finalidad de reforzar la seguridad de las fronteras europeas.  </p> <br/>
        <p>La introducción del permiso ETIAS no modificará el libre tránsito, pero sí será un requisito obligatorio de entrada a Europa por aire, tierra o mar. Entre los países latinoamericanos que necesitan solicitar el permiso se encuentran: </p> <br/>
        <p>- Argentina.</p>
        <p>- Brasil.</p>
        <p>- Chile.</p>
        <p>- Colombia.</p>
        <p>- Guatemala.</p>
        <p>- Honduras.</p>
        <p>- México.</p> 
        <p>- Nicaragua.</p>
        <p>- Panamá.</p>
        <p>- Paraguay.</p>
        <p>- Perú.</p>
        <p>- Uruguay.</p>
        <p>- Venezuela.</p>
    
      </div>
    ),
  },
  {
    question: "Asistencia al viajero para viajar a Europa",
    answer: (
      <div>
        <p>
        Si deseas viajar desde Argentina a Europa, te recomendamos contratar un seguro de viaje para toda tu estancia en la región europea. Dependiendo de la cobertura que elijas vas a poder contar con ciertos beneficios: 
        </p>
        <br />
        <p>
          - Asistencia médica por enfermedad.
        </p>
        <p>
          - Asistencia médica en caso de accidentes.
        </p>
        <p>
          - Repatriación sanitaria y administrativa.
        </p>
        <br />
        <p>
        No importa en qué momento tengas una emergencia, si viajas con ASSIST 365 estarás seguro y respaldado a donde vayas. 
        </p>{" "}
       
      </div>
    ),
  },
];

export default faqs;
